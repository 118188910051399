body {
  overflow-x: hidden; }

.Information {
  width: 100%;
  height: 100%;
  background: white; }
  .Information .ContentBox {
    width: 1200px;
    margin: 0 auto;
    padding-top: 15px; }
    .Information .ContentBox .ant-tabs-ink-bar {
      width: 70px !important;
      margin-left: 10px; }
    .Information .ContentBox .NewsContent {
      width: 100%;
      height: 100%;
      overflow: hidden; }
      .Information .ContentBox .NewsContent a {
        display: block;
        width: 1200px;
        height: 200px;
        background: #f5f9ff;
        border: 1px solid white;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
        margin: 40px 0;
        padding: 40px 0 40px 30px; }
        .Information .ContentBox .NewsContent a div {
          float: left; }
        .Information .ContentBox .NewsContent a .Title {
          height: 60px;
          color: #333;
          text-align: center;
          padding-top: 20px;
          margin-right: 16px; }
          .Information .ContentBox .NewsContent a .Title p {
            font-size: 14px; }
          .Information .ContentBox .NewsContent a .Title p:nth-child(1) {
            font-size: 30px; }
        .Information .ContentBox .NewsContent a .Image {
          width: 180px;
          height: 120px; }
          .Information .ContentBox .NewsContent a .Image img {
            width: 180px;
            height: 120px; }
        .Information .ContentBox .NewsContent a .Text {
          width: 800px;
          margin-left: 20px; }
          .Information .ContentBox .NewsContent a .Text p:nth-child(1) {
            font-size: 16px;
            color: #333;
            margin-bottom: 10px; }
          .Information .ContentBox .NewsContent a .Text p:nth-child(2) {
            font-size: 14px;
            color: #999999;
            margin-bottom: 18px; }
          .Information .ContentBox .NewsContent a .Text p:nth-child(3) {
            font-size: 14px;
            color: #999999; }
            .Information .ContentBox .NewsContent a .Text p:nth-child(3) img {
              margin-right: 10px; }
      .Information .ContentBox .NewsContent a:hover .Title {
        color: #006AEB; }
      .Information .ContentBox .NewsContent a:hover .Text p:nth-child(1) {
        color: #006AEB; }
    .Information .ContentBox .Pagination {
      width: 1200px;
      height: 100%;
      overflow: hidden;
      background: white;
      margin-bottom: 40px; }
    .Information .ContentBox .AAA .ant-tabs-bar {
      border-bottom: none; }
      .Information .ContentBox .AAA .ant-tabs-bar .ant-tabs-tab {
        border: 1px solid #ddd;
        border-radius: 4px; }
      .Information .ContentBox .AAA .ant-tabs-bar .ant-tabs-tab-active {
        background: linear-gradient(90deg, #006aeb, #009eff);
        box-shadow: 0px 3px 8px 0px #b5d4ff;
        color: #fff; }
      .Information .ContentBox .AAA .ant-tabs-bar .ant-tabs-ink-bar {
        height: 0; }
